import Alert from '../../../utils/helpers/Alert';
import { AMBIENTE, DATASALES_CRM_API } from '../../../utils/helpers/Constants';
import Fetch from '../../../utils/helpers/Fetch';
import { toBase64 } from '../../../utils/helpers/Functions';
import buildTemplate from '../helpers/BuildTemplate';

const _alert = new Alert();

const isLoadingPostTabloide = () => dispatch => dispatch({ type: 'LOAD_POST_TABLOIDE' });

const isLoadingPostTabloideLink = () => dispatch => dispatch({ type: 'LOAD_POST_TABLOIDE_LINK' });

const isLoadingPostFile = () => dispatch => dispatch({ type: 'LOAD_POST_FILE' });

const isLoadingTabloides = () => dispatch => dispatch({ type: 'LOAD_TABLOIDES' });

const isLoadingTabloideFisico = () => dispatch => dispatch({ type: 'LOAD_TABLOIDE_FISICO' });

const isLoadingTabloidsTemplatesFisico = () => dispatch =>
  dispatch({ type: 'LOAD_TABLOIDES_TEMPLATE_FISICO' });

const isLoadingTabloidsEtiquetas = () => dispatch => dispatch({ type: 'LOAD_TABLOIDES_ETIQUETAS' });

const isLoadingDetalhes = () => dispatch => dispatch({ type: 'LOAD_DETALHES' });

export const editProdutoTabloide = (payload, callback) => dispatch => {
  if (callback) {
    dispatch({
      type: 'POST_FILE',
      payload
    });
    callback();
  }
};

export const setTabloide = data => dispatch => {
  dispatch({ type: 'SET_TABLOIDE', payload: data });
};

export const setTemplate = data => dispatch =>
  dispatch({ type: 'SET_TEMPLATE', payload: buildTemplate(data) });

export const setJson = (id, json) => dispatch =>
  dispatch({ type: 'SET_JSON', payload: { json, id } });
export const set_editor = editor => ({
  type: 'SET_EDITOR',
  payload: editor
});

export const getTabloides = () => async dispatch => {
  dispatch(isLoadingTabloides());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`
  ).then(payload => dispatch({ type: 'GET_TABLOIDES', payload }));
};

export const getDetalhesTabloide = id => async dispatch => {
  dispatch(isLoadingDetalhes());

  Fetch(`${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getDetalhesTabloide`, {
    id
  }).then(payload => dispatch({ type: 'GET_DETALHES', payload }));
};

export const postFile = file => async dispatch => {
  dispatch(isLoadingPostFile());
  const xlsx = await toBase64(file);
  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecebeArquivo`,
    { ...xlsx },
    'POST'
  ).then(payload =>
    dispatch({
      type: 'POST_FILE',
      payload: {
        ...payload,
        lista_produtos: payload.lista_produtos.map(dt => ({
          ...dt,
          produto_ativo: 1
        }))
      }
    })
  );
};

export const postTabloideDigital = (data, callback) => async dispatch => {
  dispatch(isLoadingPostTabloide());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postTabloideDigital`,
    data,
    'POST'
  )
    .then(payload => dispatch({ type: 'POST_TABLOIDE', payload: payload.id }))
    .then(() => dispatch(getTabloides()));
  callback && callback();
};

export const postTabloideLink = data => async dispatch => {
  dispatch(isLoadingPostTabloideLink());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigitalLink`,
    data,
    'PUT'
  ).then(payload => {
    dispatch({ type: 'POST_TABLOIDE_LINK', payload: payload.link_tabloide });
  });
};

export const postProdutoImagemTabloide = data => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigitalLink`,
        data,
        'POST'
      );
      dispatch({ type: 'PRODUTO_IMAGEM', payload: response });

      resolve(response);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

export const putEditTabloide = data => async dispatch => {
  // dispatch(isLoadingPostTabloideLink());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTabloideDigital`,
    { tabloide: data },
    'PUT'
  )
    .then(payload => {
      dispatch({ type: 'POST_TABLOIDE_LINK', payload: payload.link_tabloide });
    })
    .then(() => dispatch(getTabloides()));
};

export const putProduto = data => async dispatch => {
  // dispatch(isLoadingPostTabloideLink());

  Fetch(
    `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTdProduto`,
    { produto: data },
    'PUT'
  ).then(payload => {
    dispatch(getDetalhesTabloide(data.id_tabloide_digital));
  });
};

export const removeTabloide = tabloide => async dispatch =>
  _alert.alertConfirm(`Deseja excluir o tabloide ${tabloide.nome_tabloide_digital} ?`, async () => {
    try {
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/removeTabloide`,
        { idTabloide: tabloide.id_tabloide_digital },
        'PUT'
      );
      dispatch(getTabloides());
    } catch (err) {
      console.log(err);
    }
  });

export const putOfertaFisico = data => async dispatch => {
  try {
    /* dispatch(isLoadingTabloideFisico()); */
    dispatch({ type: 'CLEAN_PAGINAS' });
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postEncarteFisicoPixe`,
      data,
      'POST'
    ).then(payload => {
      return 1;
    });
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const getOfertaFisico = (data, times) => async dispatch => {
  try {
    await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getEncarteFisico`, data).then(
      payload => {
        let again = false;
        payload.forEach(e => {
          if (!((e.link && e.link_html) || e.error)) {
            again = true;
          }
        });
        dispatch({ type: 'GET_OFERTA_FISICO', payload: payload });
        if (again && times < 20) {
          setTimeout(function () {
            dispatch(getOfertaFisico(data, times + 1));
          }, 5 * 1000);
        } else {
          dispatch({ type: 'FINISH_TABLOIDE_FISICO' });
        }
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const getOfertaFisicoAll =
  (data, loading = true) =>
  async dispatch => {
    try {
      if (loading) dispatch(isLoadingTabloideFisico());
      await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getEncarteFisico`, data).then(
        payload => {
          dispatch({ type: 'GET_OFERTA_FISICO_ALL', payload: payload });
          dispatch({ type: 'FINISH_TABLOIDE_FISICO' });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

export const getProductPhoto = data => async dispatch => {
  try {
    await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getProductPhoto`, data).then(
      payload => {
        dispatch({ type: 'GET_IMAGEM_PRODUTO', payload: payload });
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const getProduct = data => async dispatch => {
  try {
    await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getProduct`, data).then(
      payload => {
        dispatch({ type: 'GET_PRODUCT_ADD', payload: payload.response });
        return payload;
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const cleanProductsForAdd = () => dispatch => dispatch({ type: 'CLEAN_PRODUCTS_FOR_ADD' });

export const getTabloidsTemplatesFisico =
  (data, loading = true) =>
  async dispatch => {
    try {
      if (loading) dispatch(isLoadingTabloidsTemplatesFisico());
      await Fetch(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getTabloidsTemplate`,
        data
      ).then(payload => {
        dispatch({
          type: 'GET_TABLOIDS_TEMPLATES_FISICO',
          payload: payload.response
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getPesquisaSerpApi = data => async dispatch => {
  try {
    //dispatch(isLoadingTabloidsTemplatesFisico());
    return await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getSerpApi`, data).then(
      payload => {
        //dispatch({ type: 'GET_TABLOIDS_TEMPLATES_FISICO', payload: payload.response });
        return payload.images_results;
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const postTabloidsTemplate = data => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postTabloidsTemplate`,
      {
        ...data
      },
      'POST'
    );
  } catch (err) {
    console.log(err);
  }
};

export const deleteEncarteFisico = data => async dispatch => {
  try {
    await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/deleteEncarteFisico`,
      data,
      'DELETE'
    );
  } catch (err) {
    console.log(err);
  }
};

export const createTemplatePersonalizado = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postHerokuInsert`,
      data,
      'POST'
    ).then(payload => {
      return 1;
    });
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const addTabloidsTemplate = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/addTabloidsTemplate`,
      data,
      'POST'
    ).then(payload => {
      return 1;
    });
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const getTabloidsEtiquetas =
  (data, loading = true) =>
  async dispatch => {
    try {
      if (loading) dispatch(isLoadingTabloidsEtiquetas());
      return await Fetch(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getTabloidsEtiquetas`,
        data
      ).then(payload => {
        dispatch({ type: 'GET_TABLOIDS_ETIQUETAS', payload: payload.response });
        return payload;
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  };

export const addTabloidsEtiqueta = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/addTabloidsEtiqueta`,
      data,
      'POST'
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getVideomatik = data => async dispatch => {
  try {
    return await Fetch(`${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/getVideomatik`, data).then(
      payload => {
        return payload;
      }
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const postVideomatik = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postVideomatik`,
      data,
      'POST'
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateSequence = data => async dispatch => {
  try {
    return await Fetch(
      `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/updateSequence`,
      data,
      'PUT'
    ).then(payload => {
      return payload;
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

