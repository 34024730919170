import Skeleton from '@mui/material/Skeleton';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

import Fetch from '../../../../../utils/helpers/Fetch';
import { useStyles } from './styles';

const adjustImage = (formatId?: number) => {
  switch (formatId) {
    case 1:
      return '70ch';
    case 2:
    case 14:
    case 16:
      return '55ch';
    case 5:
      return '75ch';
    case 3:
      return '40ch';
    case 15:
      return '75ch';
    case 35:
      return '75ch';
    default:
      return '70ch';
  }
};

type ImagePolotnoProps = {
  design: string;
  designId: number;
  pixelRatio?: number;
  formatId?: number;
  onImageLoad?: (loading: boolean) => void;
  onBase64?: (image?: string) => void;
};

const ImagePolotno = ({
  design,
  pixelRatio,
  formatId,
  onImageLoad,
  onBase64,
  ...props
}: ImagePolotnoProps) => {
  const styles = useStyles();

  const { data, isFetching, isLoading } = useQuery(
    ['design-base64', design, pixelRatio],
    async () => {
      const payload = {
        design,
        format: 'jpeg',
        outputFormat: 'dataURL',
        companyId: 0,
        exportOptions: {
          pixelRatio,
          dpi: 72,
          mimeType: 'image/jpeg',
          hd: false
        }
      };

      try {
        return Fetch(
          'https://cloud.render.datasales.info/api/render',
          payload,
          'POST',
          undefined,
          undefined,
          false
        );
      } catch {
        return Fetch(
          'https://render-api-prd.datasales.info/api/render',
          payload,
          'POST',
          undefined,
          undefined,
          false
        );
      }
    },
    {
      cacheTime: 20 * 1000
    }
  );

  useEffect(() => {
    if (onImageLoad) {
      onImageLoad(!isFetching);
    }
  }, [isFetching, onImageLoad]);

  if (isLoading || (isFetching && !Boolean(onImageLoad))) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={adjustImage(formatId)}
        height="100%"
        className={styles.lazyComponents}
        sx={{ borderRadius: '8px' }}
      />
    );
  }

  return (
    <img {...props} src={data?.image} className={styles.lazyComponents} alt={`art-${formatId}`} />
  );
};

export default ImagePolotno;

